<template>
    <v-layout row class="pa-3 mb-2">
        <v-flex xs12 sm6 offset-sm3>
            <v-card flat>
                <waiting-dialog :show.sync="dialog.show" :msg="dialog.msg" :err-msg="dialog.err"></waiting-dialog>
                <v-card-title class="justify-center subheading pa-0"><b>{{ $t("takeoutpackagelist.title")}}</b></v-card-title>
                <v-layout row wrap class="mb-5">
                    <v-flex xs12 v-for="box_id in boxIds" :key="box_id">
                        <v-list subheader>
                            <v-subheader>{{ packages.find((a) => a.box_id === box_id).box_name }}({{ box_id }})</v-subheader>
                            <v-list-tile v-for="pdata in packages.filter((a) => a.box_id === box_id)" :key="pdata.track_no">
                                <v-list-tile-content>
                                    <v-list-tile-title>{{ $t("takeoutchange.cell") }} {{ pdata.door_id }} &nbsp;{{ pdata.status }} </v-list-tile-title>
                                    <v-list-tile-sub-title>{{ $t("takeoutpackagelist.phone") }}: {{ pdata.phone || "" }}</v-list-tile-sub-title>
                                    <v-list-tile-sub-title>{{ $t("takeoutpackagelist.putin-time") }}: {{ pdata.putin_time || "" }}</v-list-tile-sub-title>
                                </v-list-tile-content>
                            </v-list-tile>
                        </v-list>
                    </v-flex>
                </v-layout>
            </v-card>
            <v-card-actions class="justify-center">
                <v-btn block class="subheading" color="primary" @click="leave()"> {{ $t('takeout.back') }} </v-btn>
            </v-card-actions>
        </v-flex>
    </v-layout>
</template>

<script>
import axios from "axios";
import store, { HostUrl } from "@/store";
import BoxIdWatcherMixin from "@/mixins/BoxIdWatcher";
import PutinMixin from "@/mixins/PutinMixin";
import { getAxiosConfig } from "@/utils/AuthService";
import WaitDialogControl from "@/mixins/WaitDialogControl";
import i18n from "@/i18n";

export default {
    name: "docsClerkPackageList",
    mixins: [BoxIdWatcherMixin, PutinMixin, WaitDialogControl],
    data() {
        store.commit('setPageHome', 3)
        return {
            stepNo: 1,
            titles: [i18n.t("menu.buttons.package-list")],
            headers: [
                {
                    sortable: false,
                    width: "10px",
                },
                { text: i18n.t("takeoutchange.box-id"), value: "box_id" },
                { text: i18n.t("takeoutchange.box-name"), value: "box_name" },
                { text: i18n.t("takeoutchange.track-no"), value: "track_no" },
                { text: i18n.t("takeoutchange.cell"), value: "door_id" },
                { text: i18n.t("takeoutpackagelist.phone"), value: "phone" },
                { text: i18n.t("takeoutpackagelist.putin-time"), value: "putin_time" }
            ]
        };
    },
    computed: {
        boxIds() {
            if (this.packages.length === 0) {
                return [];
            }
            const map = new Map();
            for (const item of this.packages) {
                if (!map.has(item.box_id)) {
                    map.set(item.box_id, true); // set any value to Map
                }
            }
            console.log('mm'+map.keys());
            return Array.from(map.keys());
        }
    },
    methods: {
        async getPackageStatus() {
            let url = `/api/clerk/9/list`
            if (store.getters.user.id === 0 || url === null || url === undefined) {
                return;
            }
            this.showDialog(i18n.t("takeoutpackagelist.get-list"), i18n.t("takeout.fail-get-list"));
            let self = this;
            await axios
                .get(HostUrl + url, getAxiosConfig())
                .then(function(response) {
                    console.log(response);
                    self.serial_no = response.data.serial_no
                    const packages = response.data.packages;
                    let packageArray = [];
                    for (const track_no in packages) {
                        let { box_id, box_name, door_id, status, phone, putin_time } = packages[track_no];
                        let yy = status
                        if (yy == '未收件')
                            status = i18n.t("takeoutpackagelist.wait-to-docs-in-box")
                        else
                            status = i18n.t("takeoutpackagelist.wait-to-docs-out-box")
                        packageArray.push({
                            track_no,
                            box_id,
                            box_name,
                            door_id,
                            status,
                            phone,
                            putin_time
                        });
                    }
                    self.packages = packageArray;
                    self.closeDialog();
                })
                .catch(function(error) {
                    console.log("error", error);
                    self.closeDialog();
                });
        },
        leave() {
            this.init();
            this.$router.push(`/${i18n.locale}/docshost/menu`);
        },
    },
    activated() {
        this.getPackageStatus();
    },
};
</script>

<style scoped>
.nobull {
    list-style-type: none;
    padding-left: 0px;
}
.bold {
    font-weight: bold;
    font-size: 1.25rem;
}
</style>
